<template>
  <div v-if="product" class="product-card product">
    <ProductTag :text="tagText" />

    <div class="product-card__image">
      <ShopifyLazyImage
        v-if="featuredImage"
        :link="specificVariantProduct ? null : product"
        :to="specificVariantProduct ? specificVariantProductLink : null"
        :image="featuredImage"
        width="400"
        height="450"
        class="product" />

      <QuickShopButton
        v-if="product._meta"
        :activeProductUid="product._meta.uid"
        :modalTitle="modalTitle"
        :modalProducts="modalProducts" />
    </div>

    <div>
      <BrandLink
        v-if="product.brand || isBundle"
        :brand="product.brand"
        :isBundle="isBundle" />

      <nuxt-link
        v-if="specificVariantProduct"
        class="title"
        :to="specificVariantProductLink">
        {{ title }}
      </nuxt-link>

      <PrismicLink v-else class="title" :link="product" :text="title" />

      <span
        v-if="(shades && shades.length > 1) || specificVariantProduct"
        class="num-shades">
        {{ shadesText }}
      </span>

      <button
        v-if="product.brand && product.brand.login_to_view_pricing_on_us && locale == 'en-us' && !isLoggedIn"
        class="login-to-view-price"
        @click="openHeaderMenuExpand('login')">
        Log In to view pricing
      </button>
      <Price
        v-else-if="!hidePrice"
        :price="defaultPrice"
        :salePrice="defaultSalePrice"
        class="price" />

      <Gees v-if="product.gees" :gees="product.gees" />
    </div>
  </div>
</template>

<script>
  import productMixin from '@/mixins/productMixin'

  import PrismicLink from '@/components/prismic/PrismicLink'
  import ProductTag from '@/components/shop/ProductTag'
  import ShopifyLazyImage from '@/components/shop/ShopifyLazyImage'
  import QuickShopButton from '@/components/shop/QuickShopButton'
  import BrandLink from '@/components/shop/BrandLink'
  import Price from '@/components/shop/Price'
  import Gees from '@/components/global/Gees'

  import { getTitle } from '../../helpers'
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: 'ProductCard',

    mixins: [productMixin],

    components: {
      PrismicLink,
      ProductTag,
      ShopifyLazyImage,
      QuickShopButton,
      BrandLink,
      Price,
      Gees,
    },

    props: {
      product: {
        type: Object,
        required: true,
      },
      hidePrice: {
        type: Boolean,
        default: false,
      },
      modalTitle: {
        type: String,
        default: null,
      },
      modalProducts: {
        type: [Array, Object],
        default: null,
      },
      isVariant: {
        type: Boolean,
        default: false,
      },
    },

    inject: {
      showProductVariants: {
        from: 'showProductVariants',
        default: false,
      },
    },

    methods: {
      ...mapActions([
        'openHeaderMenuExpand'
      ]),
    },

    computed: {

      ...mapGetters([
        'isLoggedIn'
      ]),

      title() {
        return getTitle(this.product.title)
      },

      shadesText() {
        if (this.specificVariantProductTitle) return this.specificVariantProductTitle

        return `${this.shades.length} Shades`
      },
    },

    mounted() {
      this.fetchAdditionalShopifyProductInfo()
    },
  }
</script>
