<template>
  <div class="add-to-bag" :class="{ 'add-to-bag--no-quantity': hideQuantity }">
    <Button
      v-if="overrideLink"
      :link="overrideLink"
      :text="overrideText ? overrideText : 'Shop Now'"
      class="button--add-to-bag" />

    <template v-else>
      <div v-if="isPreOrder" class="add-to-bag__preorder">
        <div
          id="preproduct-pledge"
          :data-id="product.id"
          data-headless="true"
          ref="preOrder"></div>
        <script
          type="text/javascript"
          async
          src="https://preproduct.onrender.com/preproduct-embed.js"></script>
        <p v-if="preOrderVariantAvailable">{{ preOrderText }}</p>
      </div>

      <template v-if="isVisible && !preOrderVariantAvailable">
        <div class="add-to-bag__add">
          <Quantity v-if="!hideQuantity" v-model="quantity" />

          <button
            class="button button--add-to-bag pp-no-hide"
            :class="{ 'disabled--dark' : this.isComingSoon}"
            @click="handleClick"
            :disabled="isSoldOut || isUnavailable || isComingSoon"
            :type="disableAutoSubmit ? 'button' : 'submit'">
            <Price v-if="price" :price="price" :salePrice="salePrice" />

            <span>
              {{ buttonText }}
            </span>
          </button>
        </div>

        <Modal v-if="isSubscription" type="subscription-confirm">
          <template v-slot:title>
            <h2>Proceed to Checkout</h2>
          </template>
          <template v-slot:body>
            <p>Subscription products must be purchased individually. Proceed to checkout below. Note that when you return the items that are currently in your cart will still be there.</p>
            <div class="button-wrapper">
              <Button
                @click="goToSubscriptionCheckout"
                text="Proceed to Checkout"
              />
              <Button
                @click="closeModal"
                text="Go Back"
                :isHollow="true"
              />
            </div>
          </template>
        </Modal />

        <p
          v-if="!isSoldOut && !isUnavailable && !hideSezzle"
          class="add-to-bag__sezzle">
          or 4 interest-free payments of ${{
            ((salePrice ? salePrice : price) / 4) | currency
          }}
          with
          <a
            href="https://shopper-help.sezzle.com"
            target="_blank"
            rel="nofollow">
            Sezzle
          </a>
        </p>

        <div
          v-if="isSoldOut && !hideBackInStock && product"
          class="add-to-bag__back-in-stock">
          <Button
            text="Notify Me When Back In Stock"
            @click="openBackInStockModal(product)"
            :isFakeButton="true" />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { getTitle, sanitizeShopifyId } from '@/helpers'

  import Quantity from '@/components/shop/Quantity'
  import Button from '@/components/global/Button'
  import Price from '@/components/shop/Price'
  import Modal from '@/components/global/Modal'

  export default {
    name: 'AddToBag',

    data() {
      return {
        isVisible: false,
        preOrderVariantAvailable: false,
      }
    },

    components: {
      Quantity,
      Button,
      Price,
      Modal
    },

    props: {
      product: {
        type: Object,
        default: null,
      },
      overrideLink: {
        type: Object,
        default: null,
      },
      overrideText: {
        type: Object,
        default: null,
      },
      isSoldOut: {
        type: Boolean,
        default: false,
      },
      hideQuantity: {
        type: Boolean,
        default: false,
      },
      hideBackInStock: {
        type: Boolean,
        default: false,
      },
      hideSezzle: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Number,
        default: 1,
      },
      defaultPrice: {
        type: [String, Number],
        default: null,
      },
      defaultSalePrice: {
        type: [String, Number],
        default: null,
      },
      isProceed: {
        type: Boolean,
        default: false,
      },
      isSubscription: {
        type: Boolean,
        default: false,
      },
      disableAutoSubmit: {
        type: Boolean,
        default: false,
      },
      isQuickShop: {
        type: Boolean,
        default: false,
      },
      isPreOrder: {
        type: Boolean,
        default: false,
      },
      preOrderText: {
        type: String,
        default: null,
      },
      isUnavailable: {
        type: Boolean,
        default: false,
      },
      isComingSoon: {
        type: Boolean,
        default: false,
      }
    },

    computed: {
      ...mapGetters([
        'numCartItems'
      ]),
      quantity: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      price() {
        return this.product ? this.product.price : this.defaultPrice
      },
      salePrice() {
        return this.product ? this.product.salePrice : this.defaultSalePrice
      },
      hasVariants() {
        return this.product.variantTitle
      },
      buttonText() {
        if (this.overrideText) {
          return this.overrideText
        }

        if (this.isUnavailable) {
          return 'Unavailable'
        }

        if(this.isComingSoon) {
          return '*Coming Soon'
        }

        if (this.isSoldOut) {
          return 'Sold Out'
        }

        if (this.isSubscription) {
          return 'Check Out'
        }

        return this.isProceed ? 'Proceed' : 'Add To Bag'
      },
    },

    watch: {
      product() {
        this.isSubscription = false;
        if (this.product.frequency == 'subscription') {
          this.isSubscription = true;
        }
        this.handlePreOrderVariantAvailabillity()
      },
    },

    methods: {
      ...mapActions([
        'addItemToCart',
        'openHeaderMenuExpand',
        'openModal',
        'closeModal',
        'openBackInStockModal',
      ]),

      handlePreOrderVariantAvailabillity() {
        if (this.isPreOrder) {
          if (window.getComputedStyle(this.$refs.preOrder).display === 'none') {
            this.preOrderVariantAvailable = false
          } else {
            this.preOrderVariantAvailable = true
          }
        }
      },

      handleTracking() {
        if (!this.product) return null

        const variantId = this.product.variantId
          ? sanitizeShopifyId('ProductVariant', this.product.variantId)
          : null

        this.$gtag.event('add_to_cart', {
          id: variantId ?? this.product.id,
          name: this.product.title,
          variant: this.product.variantTitle ?? this.product.title,
          brand: this.product.brand
            ? getTitle(this.product.brand.title)
            : 'Gee Beauty',
          category:
            this.product.product_type &&
            getTitle(this.product.product_type.title),
          quantity: this.quantity,
          price: this.product.price,
        })

        this.$pinterest.addToCart({
          product_id: variantId,
          value: this.price,
          order_quantity: this.quantity,
          currency: this.$store.state.locale === 'en-ca' ? 'CAD' : 'USD',
        })

        this.$fb.addToCart({
          content_ids: [variantId ?? this.product.id],
          content_type: 'product',
          content_name: this.product.title,
          value: this.product.price * this.quantity,
          currency: this.$store.state.locale === 'en-ca' ? 'CAD' : 'USD',
        })
      },

      handleClick() {

        if (this.disableAutoSubmit) {
          this.$emit('click')
          return false
        }

        if (!this.isSoldOut && !this.isUnavailable && !this.isComingSoon) {
          if (this.isQuickShop) {
            this.closeModal()
          }

          if(this.product.frequency == 'subscription') {
            if (this.numCartItems == 0) {
              this.goToSubscriptionCheckout();
            } else {
              this.openModal('subscription-confirm');
            }

            return false;
          }

          this.addItemToCart(this.product)

          this.openHeaderMenuExpand('cart')

          this.handleTracking()
        }
      },

      forcePreOrderAvailabilityCheck() {
        if (this.hasVariants) {
          const initialDisplay = window.getComputedStyle(
            this.$refs.preOrder
          ).display

          const myInterval = setInterval(() => {
            if (
              initialDisplay !==
              window.getComputedStyle(this.$refs.preOrder).display
            ) {
              clearInterval(myInterval)
              this.handlePreOrderVariantAvailabillity()
              this.isVisible = true
            }
          }, 100)
        } else {
          this.preOrderVariantAvailable = true
          this.isVisible = true
        }
      },

      goToSubscriptionCheckout() {
        let urlParams = new URLSearchParams(window.location.search);
        const { domain } = this.$config[this.$store.state.locale]
        const variantId = this.product.variantId.replace(`gid://shopify/ProductVariant/`, '')
        window.location = `https://checkout.${domain}/subscription/?type=`+this.product.subscriptionFrequency+'&product='+this.product.handle+'&variant='+variantId;
      }
    },

    mounted() {

      if (this.isPreOrder) {
        this.forcePreOrderAvailabilityCheck()
      } else {
        this.isVisible = true
      }
    },
  }
</script>
