<template>
  <section id="reviews" class="reviews" :class="{ 'is-open': isOpen }">
    <div class="reviews__overview row">
      <div class="column small-12 large-3">
        <Stars :numStars="reviews && reviews.averageScore" />
      </div>

      <div class="column small-12 large-6">
        <button
          v-if="hasReviews"
          class="reviews__trigger"
          @click="$emit('toggleReviews')">
          <Stars :numStars="reviews && reviews.averageScore" />
          <div>
            All Reviews
            <span>({{ reviews && reviews.totalNum }})</span>
            <SvgIcon name="chevron-down" />
          </div>
        </button>
        <div v-else class="reviews__trigger">
          <div>
            All Reviews
            <span>(0)</span>
          </div>
        </div>
      </div>

      <div class="column small-12 large-3">
        <ArrowLink text="Leave A Review" @click="openLeaveAReview" />
      </div>
    </div>

    <SlideUpDown v-if="hasReviews" :active="isOpen">
      <transition>
        <div v-if="isOpen" class="reviews__content row">
          <div class="column small-12 large-10 xlarge-8">
            <div class="reviews__content__top">
              <ArrowLink text="Leave A Review" @click="openLeaveAReview" />
            </div>

            <div class="reviews__content__bottom">
              <Review
                v-for="review in reviews.visible"
                :key="review.id"
                :review="review" />

              <div class="row">
                <div class="column small-12 large-9 large-offset-3">
                  <div class="button-wrapper">
                    <LoadMoreButton
                      v-if="reviews.hasMore"
                      @click="$emit('loadMore')"
                      size="small" />

                    <Button
                      size="small"
                      text="Leave A Review"
                      @click="openLeaveAReview" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </SlideUpDown>
  </section>
</template>

<script>
  import { mapActions } from 'vuex'

  import SlideUpDown from 'vue-slide-up-down'

  import Stars from '@/components/global/Stars'
  import SvgIcon from '@/components/global/SvgIcon'
  import ArrowLink from '@/components/global/ArrowLink'
  import Review from '@/components/shop/reviews/Review'
  import LoadMoreButton from '@/components/global/LoadMoreButton'
  import Button from '@/components/global/Button'

  export default {
    name: 'Reviews',

    components: {
      SlideUpDown,
      Stars,
      SvgIcon,
      ArrowLink,
      Review,
      LoadMoreButton,
      Button,
    },

    props: {
      product: {
        type: Object,
        default: null,
      },
      productId: {
        type: Number,
        default: null,
      },
      reviews: {
        type: Object,
        default: null,
      },
      isOpen: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      hasReviews() {
        return (
          this.reviews &&
          this.reviews.visible &&
          this.reviews.visible.length > 0
        )
      },
    },

    methods: {
      ...mapActions(['openReviewExpand']),
      openLeaveAReview() {
        this.openReviewExpand({
          product: this.product,
          productId: this.productId,
        })
      },
    },
  }
</script>
